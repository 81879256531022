import { ApolloProvider } from "@apollo/client";
import React from "react";
import { AppProvider } from "./src/components/context";
import { AuthProvider } from "./src/components/useAuth";
import { client } from "./lib/apolloClient";
import "./styles/global.css";

export const wrapRootElement = ({ element }) => {
  return (
    <ApolloProvider client={client}>
      <AuthProvider>
        <AppProvider>{element}</AppProvider>
      </AuthProvider>
    </ApolloProvider>
  );
};

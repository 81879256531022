import { ApolloError, gql, useQuery } from "@apollo/client";
import React, { ReactNode, createContext, useContext, useMemo } from "react";

export interface User {
  id: string;
  databaseId: number;
  firstName: string;
  lastName: string;
  email: string;
  capabilities: string[];
}

interface AuthData {
  loggedIn: boolean;
  user?: User;
  isUnlicensedUser: boolean;
  isBankUser: boolean;
  isOtherBankUser: boolean;
  isBankUser3: boolean;
  isCloudTalkUser: boolean;
  isBankAcc: boolean;
  loading: boolean;
  error?: ApolloError;
  isCustomerDefault: boolean;
}

const DEFAULT_STATE: AuthData = {
  isUnlicensedUser: false,
  isCustomerDefault: true,
  isBankUser: false,
  isOtherBankUser: false,
  isBankUser3: false,
  isCloudTalkUser: false,
  isBankAcc: false,
  loggedIn: false,
  user: undefined,
  loading: true,
  error: undefined,
};

const AuthContext = createContext(DEFAULT_STATE);

export const GET_USER = gql`
  query getUser {
    viewer {
      id
      databaseId
      firstName
      lastName
      email
      capabilities
    }
  }
`;

export function AuthProvider({ children }: { children: ReactNode }) {
  const { data, loading, error } = useQuery(GET_USER);

  const value = useMemo(() => {
    const user = data?.viewer;
    const isCustomerDefault = data?.viewer?.capabilities?.includes("customer");
    const isUnlicensedUser = data?.viewer?.capabilities?.includes(
      "customer_unlicensed",
    );
    const isBankUser = data?.viewer?.capabilities?.includes("customer_bank");
    const isOtherBankUser = data?.viewer?.capabilities?.includes("bank_user_2");
    const isBankUser3 = data?.viewer?.capabilities?.includes("bank_user_3");
    const isCloudTalkUser = data?.viewer?.capabilities?.includes("cloudtalk");
    const isBankAcc = data?.viewer?.capabilities?.includes("bankacc");

    const loggedIn = Boolean(user);

    return {
      loggedIn,
      isUnlicensedUser,
      isCustomerDefault,
      isBankUser,
      isOtherBankUser,
      isBankUser3,
      isCloudTalkUser,
      isBankAcc,
      user,
      loading,
      error,
    };
  }, [data, loading, error]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

const useAuth = () => useContext(AuthContext);

export default useAuth;
